import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import ally from "ally.js";
import broadcaster from "../../utils/broadcast";
import { ajaxResponse } from "../../utils/forms";

export default class extends Controller {
  static targets = ["container", "content", "remoteContent", "primaryForm"];

  initialize() {
    this.handleEscape = this.handleEscape.bind(this);
  }

  disconnect() {
    // On Turbo Drive pages we need to remove the drawer portal from the DOM before
    // the Turbo Drive snapshot is taken. This way when the back/forward browser buttons
    // there aren't leftover elements with duplicate ids.
    if (this.element.id !== "%PORTAL_ID%") {
      this.element.remove();
    }
  }

  show() {
    if (
      (!this.fetched && !this.fetching) ||
      this.data.get("refetch") === "true" ||
      this.data.has("serialize")
    ) {
      this.handleRemoteContent();
    } else {
      this.data.set("cached-content", this.contentTarget.innerHTML);
    }
    document.body.classList.add("layout--overflow-hidden");
    document.documentElement.classList.add("layout--overflow-hidden");
    this.containerTarget.classList.add("voyager-drawer__container--open");
    this.status = "opened";

    this.stateClosed = false;
    this.handleCollapseFilterShow();
    this.restrictFocus();
    this.addEventListeners();

    this.focusElement();
  }

  preFetch() {
    if (this.fetched || this.fetching || this.data.has("serialize")) return;
    this.handleRemoteContent();
  }

  hide() {
    if (this.preventHide()) return;
    this.allowFocus();
    this.removeEventListeners();

    document.body.classList.remove("layout--overflow-hidden");
    document.documentElement.classList.remove("layout--overflow-hidden");

    this.handleCollapseFilterHide();

    if (this.source) {
      this.source.cancel("User Canceled Request");
    }

    this.containerTarget.classList.remove("voyager-drawer__container--open");
    this.status = "closed";
    
    if (this.element.id === "referral_drawer") {
      location.reload();
    }
    
    if (this.data.get("refetch") === "true") {
      this.resetPrimaryForm();
    }
  }

  hideFromApplicantsTable() {
    this.hide();
    this.bulkActionsController.deselectApplicantColumn();
  }

  preventHide() {
    const hideEvent = new CustomEvent("drawer_portal:hide", {
      bubbles: true,
      cancelable: true
    });
    const cancelled = !this.element.dispatchEvent(hideEvent);
    return cancelled;
  }

  broadcast(status) {
    broadcaster.portalMessage(this.element.id, status);
  }

  hideAndReset() {
    this.hide();
    this.contentTarget.innerHTML = this.data.get("cached-content");
  }

  addEventListeners() {
    this.element.addEventListener("keyup", this.handleEscape);
  }

  removeEventListeners() {
    this.element.removeEventListener("keyup", this.handleEscape);
  }

  handleEscape(event) {
    if (event.key === "Escape") {
      this.hide();
    }
  }

  restrictFocus() {
    this.disableFocusHandler = ally.maintain.tabFocus({
      context: [
        this.element,
        document.getElementById("toast-container"),
        document.getElementById("remote-modal-container")
      ]
    });
  }

  allowFocus() {
    if (this.disableFocusHandler) {
      this.disableFocusHandler.disengage();
    }
  }

  focusElement() {
    setTimeout(() => {
      const element = ally.query.firstTabbable({
        context: this.element,
        defaultToContext: true
      });
      element.focus();
    }, 500);
  }

  handleRemoteContent() {
    if (!this.data.get("remotePath") || !this.hasRemoteContentTarget) return;

    this.fetching = true;

    if (this.source) {
      this.source.cancel();
    }

    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
    this.fetched = false;

    axios
      .get(this.fullRemotePath, { cancelToken: this.source.token })
      .then(({ data }) => {
        this.source = null;
        this.fetched = true;
        this.fetching = false;
        this.remoteContentTarget.innerHTML =
          typeof data === "object" ? data.html : data;
        this.data.set("cached-content", this.contentTarget.innerHTML);
        this.element.dispatchEvent(
          new CustomEvent("drawer_portal:fetched", {
            bubbles: true,
            cancelable: true
          })
        );
      })
      .catch((err) => {
        this.source = null;
        this.fetching = false;
        this.fetched = false;
        if (axios.isCancel(err)) {
        } else {
          // Handle generic error
        }
      });
  }

  handleJsonRedirect(event) {
    const json = JSON.parse(ajaxResponse(event));
    if (json.redirect) {
      this.performRedirect(json.redirect);
    }
  }

  handleRedirect(event) {
    const url = event.target.getAttribute("data-url");
    if (url) this.performRedirect(url);
  }

  performRedirect(url) {
    const origUrl = this.data.get("remotePath");
    this.data.set("remotePath", url);
    this.handleRemoteContent();
    this.data.set("remotePath", origUrl);
  }

  submitPrimaryForm(evt) {
    evt.preventDefault();
    if (!this.hasPrimaryFormTarget) return;

    const event = new CustomEvent("submit", {
      bubbles: true,
      cancelable: true
    });
    this.primaryFormTarget.dispatchEvent(event);
  }

  resetPrimaryForm() {
    if (!this.hasPrimaryFormTarget) return;

    this.primaryFormTarget.innerHTML = "";
  }

  handleCollapseFilterShow() {
    if (!this.bulkActionDrawer) return;

    if ($("#appFilterController").hasClass("closed")) {
      $("#appFilterController").removeClass("closed");
      this.stateClosed = true;
    }
    $("#appFilterCollapse span").addClass("d-none");
  }

  handleCollapseFilterHide() {
    if (!this.bulkActionDrawer) return;

    if (this.stateClosed === true) {
      $("#appFilterController").addClass("closed");
    }
    if (this.bulkActionDrawer) {
      $("#appFilterCollapse span").removeClass("d-none");
    }
  }

  get status() {
    return this.data.get("status") || "closed";
  }

  set status(status) {
    this.broadcast({ opened: status === "opened" });
    return this.data.set("status", status);
  }

  get isOpen() {
    return this.status === "opened";
  }

  get fullRemotePath() {
    let result = this.data.get("remotePath");
    if (this.data.has("serialize")) {
      const args = $(this.data.get("serialize")).serialize();
      if (result.includes("?")) {
        result = `${result}&${args}`;
      } else {
        result = `${result}?${args}`;
      }
    }
    return result;
  }

  get bulkActionDrawer() {
    return this.element.id == "drawer-app-bulk-actions";
  }

  get bulkActionsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("appIndexController"),
      "bulk-actions"
    );
  }

}

export function portal(element, application, callback) {
  const portal = element.closest("[data-controller='drawer-portal']");
  const drawer = application.getControllerForElementAndIdentifier(
    portal,
    "drawer-portal"
  );
  if (drawer) {
    callback(drawer);
  }
}
