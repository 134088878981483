import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["form", "modal"];

  confirmFileSelection(event) {
    event.preventDefault();
    $(this.modalTarget).modal({ backdrop: "static", keyboard: false });
    $(this.modalTarget).modal("show");
    this.fileEvent = event;
  }

  cancel() {
    $(this.modalTarget).modal("hide");
    this.formTarget.value = '';
  }

  confirm() {
    $(this.modalTarget).modal("hide");
    if (this.fileEvent) {
      this.fileEvent.target.form.requestSubmit();
    }
  }

  disconnect() {
    this.fileEvent = null;
    $(this.modalTarget).modal("hide");
  }
}
